import { createSelector } from '@ngrx/store'
import { get } from 'lodash'
import {
  LineItemExtended,
  TaxItem,
} from '../../../_core/models/fleet-order.models'
import {
  selectAuthorizedFleetAccounts,
  selectAuthorizedFleetCompanies,
  selectAuthorizedFleetCustomer,
  selectProducts,
  selectSelectedFleetCompany,
} from '../../../_core/root-store/user/user.selectors'
import { VehicleDetailsTableRow as FueledAssetsTableRow } from '../../../services-page/service-details/service-details.interface'
import { ProductsDictionary } from '../../../_shared/typings'
import { createProductsDictionary } from '../../../_shared/helpers'
import { VisibleColumns } from '../../../_core/models/fleet-customer.models'
import { AppStateWithBillingDetails } from './billing-details.reducer'
import { AuthorizedFleetAccount } from '../../../_core/models/customer.models'
import {
  getEnergyTypeLabel,
  getFuelGroup,
} from '../../../_core/utils/energy-type-dictionary.util'
import { format, parse } from 'date-fns'
import { calculateTaxTotal } from '@fleet-customer/utils/fleet-stats'
import { FueledAsset, TotalCharges } from '../../typings'

const selectBillingPageDetailsState = (state: AppStateWithBillingDetails) =>
  state.billingDetails

export const selectBillingDetails = createSelector(
  selectBillingPageDetailsState,
  selectProducts,
  selectAuthorizedFleetAccounts,
  selectAuthorizedFleetCompanies,
  selectSelectedFleetCompany,
  selectAuthorizedFleetCustomer,
  (
    {
      autoPay,
      fleetInvoice,
      includedServices,
      file,
      isFleetInvoiceDownloading,
      appliedCreditMemosAmount,
    },
    products,
    authorizedFleetAccounts,
    authorizedFleetCompanies,
    selectedFleetCompany,
    authorizedFleetCustomer
  ) => {
    if (
      fleetInvoice === null ||
      products === null ||
      authorizedFleetAccounts === null ||
      authorizedFleetCompanies === null ||
      selectedFleetCompany === null ||
      authorizedFleetCustomer === null
    ) {
      return null
    }

    let totalGallons = 0

    const productsDictionary = createProductsDictionary(products || [])

    const fueledAssets: FueledAsset[] = []

    for (const fleetOrderId of Object.keys(includedServices)) {
      const { service, findOptions } = includedServices[fleetOrderId]
      const {
        lineItems,
        totalCharged,
        fleetAccountId,
        frozenFleetAccountParams,
        fuelGroups: fleetOrderFuelGroups,
      } = service

      const authorizedFleetAccount = authorizedFleetAccounts.find(
        (fleetAccount) => fleetAccount._id === fleetAccountId
      )

      let visibleColumns: VisibleColumns = {
        plate: false,
        vehicleNumber: false,
        vin: false,
        description: true,
        subAccount: false,
        quantity: false,
        rate: false,
        subtotal: false,
        tax: false,
        fee: false,
        discount: false,
        totalPrice: false,
      }

      const emptyColumns: Partial<VisibleColumns> = {
        plate: true,
        vehicleNumber: true,
        vin: true,
        subAccount: true,
        discount: true,
        tax: false,
      }

      const isFeeColumnEmpty = !(lineItems || []).some(
        (li) => li.type === 'FEE' && !!li.fleetVehicleId
      )

      const { subAccountId, skip, limit } = findOptions
      const vehicleFeeLineItems = lineItems.filter(
        (li) => li.type === 'FEE' && !!li.fleetVehicleId && !li.waived
      )

      const { fueledAssetsTableRows, totalCharges, totalServiceGallons } =
        processLineItems({
          lineItems,
          vehicleFeeItems: vehicleFeeLineItems,
          productsDictionary,
          subAccountFilter: subAccountId,
          emptyColumns,
          authorizedFleetAccount,
          frozenFleetAccountParams,
          fleetOrderFuelGroups,
          totalCharged,
        })
      const totalCount = fueledAssetsTableRows.length
      totalGallons += totalServiceGallons

      const fleetOrderDetailsColumns =
        authorizedFleetCustomer.dashboardSettings.fleetOrderDetailsColumns
      visibleColumns = {
        ...visibleColumns,
        ...fleetOrderDetailsColumns,
        plate: fleetOrderDetailsColumns.plate && !emptyColumns.plate,
        vehicleNumber:
          fleetOrderDetailsColumns.vehicleNumber && !emptyColumns.vehicleNumber,
        vin: fleetOrderDetailsColumns.vin && !emptyColumns.vin,
        subAccount:
          fleetOrderDetailsColumns.subAccount && !emptyColumns.subAccount,
        fee: !isFeeColumnEmpty,
        discount: fleetOrderDetailsColumns.discount && !emptyColumns.discount,
      }

      fueledAssets.push({
        totalCount,
        totalCharges,
      })
    }

    // if no pumped gallons, no charges/fees, no vehicle count it is misc/def invoice
    const totalChargesAndCountSum = fueledAssets.reduce(
      (sum, fueledAsset) =>
        sum + fueledAsset.totalCharges.subTotal + fueledAsset.totalCount,
      0
    )
    const isMiscOrDefInvoice =
      totalGallons === 0 && totalChargesAndCountSum === 0

    return {
      autoPay,
      fleetInvoice,
      includedServices,
      fueledAssets,
      file,
      isFleetInvoiceDownloading,
      totalGallons,
      appliedCreditMemosAmount,
      isMiscOrDefInvoice,
    }
  }
)

export const selectNetsuiteCustomerId = createSelector(
  selectBillingPageDetailsState,
  selectAuthorizedFleetAccounts,
  selectAuthorizedFleetCompanies,
  ({ fleetInvoice }, authorizedFleetAccounts, authorizedFleetCompanies) => {
    if (fleetInvoice === null || authorizedFleetAccounts === null) {
      return null
    }

    if (fleetInvoice.fleetAccountId) {
      const authorizedFleetAccount = authorizedFleetAccounts.find(
        (account) => account._id === fleetInvoice.fleetAccountId
      )

      return get(authorizedFleetAccount, 'netsuiteCustomerId', null)
    }

    const authorizedFleetCompany = authorizedFleetCompanies.find(
      (company) => company._id === fleetInvoice.fleetCompanyId
    )
    return get(authorizedFleetCompany, 'netsuiteCustomerId', null)
  }
)

interface SubTotalRows {
  [key: string]: {
    item: string
    quantity: number
    rate: number
    subTotal: number
  }
}

function processLineItems({
  lineItems,
  vehicleFeeItems,
  productsDictionary,
  subAccountFilter,
  emptyColumns,
  authorizedFleetAccount,
  frozenFleetAccountParams,
  fleetOrderFuelGroups,
  totalCharged,
}: {
  lineItems: LineItemExtended[]
  vehicleFeeItems: LineItemExtended[]
  productsDictionary: ProductsDictionary
  subAccountFilter: string
  emptyColumns: Partial<VisibleColumns>
  authorizedFleetAccount: AuthorizedFleetAccount
  frozenFleetAccountParams: object
  fleetOrderFuelGroups: object
  totalCharged: number
}) {
  const fuelGroups = get(authorizedFleetAccount, 'fuelGroups', null)
  const frozenFuelGroups = get(frozenFleetAccountParams, 'fuelGroups', null)
  const fuelGroupsOnFleetOrder = frozenFuelGroups || fleetOrderFuelGroups
  const shouldUseEquipmentVehicleFeeDiesel = fuelGroupsOnFleetOrder
    ? get(fuelGroupsOnFleetOrder, 'diesel.useEquipmentVehicleFee')
    : get(fuelGroups, 'diesel.useEquipmentVehicleFee')
  const shouldUseEquipmentVehicleFeeGas = fuelGroupsOnFleetOrder
    ? get(fuelGroupsOnFleetOrder, 'gas.useEquipmentVehicleFee')
    : get(fuelGroups, 'gas.useEquipmentVehicleFee')
  const fueledAssetsTableRows: FueledAssetsTableRow[] = []
  const feeSubTotals: SubTotalRows = {}
  const fuelSubTotals: SubTotalRows = {}
  const totalCharges: TotalCharges = {
    subTotal: 0, // before taxes
    taxes: 0,
    adminFeeCharges: 0,
    lowGallonFeeCharges: 0,
    processingCharges: 0,
    totalPayable: totalCharged || 0, // after taxes, total amount to pay
  }

  for (const lineItem of lineItems) {
    if (lineItem.waived || lineItem.quantity === 0) {
      continue
    }

    const isProcessingCharge = lineItem.type === 'PROCESSING_CHARGE'
    const isLowGallonFee =
      lineItem.type === 'FEE' && lineItem.feeType === 'LOW_GALLON'
    const isAdminFee = lineItem.type === 'FEE' && lineItem.feeType === 'ADMIN'
    const isVisitFee = lineItem.type === 'FEE' && lineItem.feeType === 'VISIT'
    const isVehicleFee =
      lineItem.type === 'FEE' &&
      lineItem.feeType === 'VEHICLE' &&
      !lineItem.fleetVehicleId
    const isFuelLineItem = lineItem.type === 'FUEL'
    const isBulkVehicleFee =
      lineItem.type === 'FEE' && lineItem.feeType === 'BULK_VEHICLE'

    if (isFuelLineItem || isVisitFee || isVehicleFee) {
      totalCharges.subTotal += lineItem.subtotal || 0
    }

    totalCharges.taxes += calculateTaxTotal(lineItem.taxItems || [])

    if (isProcessingCharge) {
      totalCharges.processingCharges += lineItem.total
    }

    if (isLowGallonFee) {
      totalCharges.lowGallonFeeCharges += lineItem.total
    }

    if (isAdminFee) {
      totalCharges.adminFeeCharges += lineItem.total
    }

    const fleetVehicle = lineItem.fleetVehicle
    if (fleetVehicle) {
      const { plate, vehicleNumber, vin, subAccountId } = fleetVehicle
      const hasDiscount = !!lineItem.fuelDiscountPerGallon
      if (plate && emptyColumns.plate) emptyColumns.plate = false
      if (vehicleNumber && emptyColumns.vehicleNumber)
        emptyColumns.vehicleNumber = false
      if (vin && emptyColumns.vin) emptyColumns.vin = false
      if (subAccountId && emptyColumns.subAccount)
        emptyColumns.subAccount = false
      if (hasDiscount && emptyColumns.discount) emptyColumns.discount = false
    }

    if (isVisitFee) {
      const feeItem = `Visit Fee - ${lineItem.fuelGroup}`
      feeSubTotals[feeItem] = {
        item: feeItem,
        quantity: 1,
        rate: lineItem.basePricePerUnitWithMargin,
        subTotal: lineItem.total,
      }
    }

    if (isVehicleFee || isBulkVehicleFee) {
      const feeItem = `Vehicle Fee ${lineItem.price}`
      if (!feeSubTotals[feeItem]) {
        feeSubTotals[feeItem] = {
          item: 'Vehicle Fee',
          quantity: 0,
          rate: 0,
          subTotal: 0,
        }
      }
      feeSubTotals[feeItem].quantity += lineItem.quantity
      feeSubTotals[feeItem].rate = lineItem.basePricePerUnitWithMargin
      feeSubTotals[feeItem].subTotal += lineItem.total
    }

    if (isFuelLineItem) {
      const { vehicleDetails, vehicleFeeItem } = createVehicleDetail(
        lineItem,
        vehicleFeeItems,
        productsDictionary
      )

      if (hasProvidedSubAccountId(lineItem, subAccountFilter)) {
        fueledAssetsTableRows.push(vehicleDetails)
      }

      const fuelName = vehicleDetails.fuel
      const fuelType = get(lineItem, 'fleetVehicle.fuelType')
      const fuelGroup = getFuelGroup(fuelType)
      const isEquipmentFee =
        vehicleDetails.isEquipment &&
        ((shouldUseEquipmentVehicleFeeGas && fuelGroup === 'GAS') ||
          (shouldUseEquipmentVehicleFeeDiesel && fuelGroup === 'DIESEL'))
      const feeItem = isEquipmentFee
        ? `Fees for ${fuelName} fuel per equipment`
        : `Fees for ${fuelName} fuel per vehicle`

      if (!feeSubTotals[feeItem]) {
        feeSubTotals[feeItem] = {
          item: feeItem,
          quantity: 0,
          rate: 0,
          subTotal: 0,
        }
      }

      if (vehicleFeeItem) {
        feeSubTotals[feeItem].quantity++
        feeSubTotals[feeItem].rate = vehicleFeeItem.price
        feeSubTotals[feeItem].subTotal += vehicleFeeItem.total
      }

      if (!fuelSubTotals[fuelName]) {
        fuelSubTotals[fuelName] = {
          item: fuelName,
          quantity: 0,
          rate: 0,
          subTotal: 0,
        }
      }

      fuelSubTotals[fuelName].quantity += lineItem.quantity
      fuelSubTotals[fuelName].rate = lineItem.basePricePerUnitWithMargin
      fuelSubTotals[fuelName].subTotal += lineItem.subtotal || 0
    }
  }

  return {
    fueledAssetsTableRows,
    pricePerGallonTableRows: [
      ...Object.values(fuelSubTotals).filter((ppg) => ppg.subTotal > 0),
      ...Object.values(feeSubTotals)
        .filter((ppg) => ppg.subTotal > 0)
        .sort(sortVisitFeesToTop)
        .map((row) => ({
          ...row,
          isFee: true,
        })),
    ],
    totalCharges,
    totalServiceGallons: Object.values(fuelSubTotals).reduce(
      (acc, ppg) => acc + ppg.quantity,
      0
    ),
  }
}

function createVehicleDetail(
  lineItem: LineItemExtended,
  vehicleFeeItems: LineItemExtended[],
  productsDictionary: ProductsDictionary
) {
  const vehicleFeeItem = getVehicleFeeItem(lineItem, vehicleFeeItems)
  let vehicleFee = 0
  let total = lineItem.total || 0
  let feeTaxItems: TaxItem[] = []

  if (vehicleFeeItem) {
    vehicleFee = vehicleFeeItem.total || 0
    total += vehicleFee
    feeTaxItems = [...(vehicleFeeItem.taxItems || [])]
    if (feeTaxItems.length > 0) {
      const baseFeePerVehicle =
        vehicleFeeItem.total - calculateTaxTotal(feeTaxItems)
      feeTaxItems.unshift({
        taxDescription: 'Vehicle fee',
        taxCalculatedValue: baseFeePerVehicle,
      })
      feeTaxItems = feeTaxItems.map((taxItem) => {
        if (taxItem.taxIgenId === 'PMT_PROC') {
          return { ...taxItem, taxDescription: 'Fuel Payment Processing' }
        }
        return taxItem
      })
    }
  }

  const vehicleDetails: Partial<FueledAssetsTableRow> = {}
  vehicleDetails.fleetVehicleId = get(lineItem, 'fleetVehicleId', null)
  vehicleDetails.plate = get(lineItem, 'fleetVehicle.plate') || '-'
  vehicleDetails.vehicleNumber =
    get(lineItem, 'fleetVehicle.vehicleNumber') || '-'
  vehicleDetails.vin = get(lineItem, 'fleetVehicle.vin') || '-'
  vehicleDetails.fuel = getFuelValue(lineItem, productsDictionary)
  vehicleDetails.subAccount =
    get(lineItem, 'fleetVehicle.subAccount.name') || '-'
  vehicleDetails.quantity = `${lineItem.quantity || '-'}`
  vehicleDetails.rate = `${lineItem.basePricePerUnitWithMargin || '-'}`
  vehicleDetails.fee = `${vehicleFee || '-'}`
  vehicleDetails.subtotal = lineItem?.subtotal

  vehicleDetails.total = `${total || '-'}`
  vehicleDetails.feeTaxItems = feeTaxItems
  // tax related props
  const taxTotal = calculateTaxTotal(lineItem.taxItems)
  vehicleDetails.tax = `${taxTotal || '-'}`
  vehicleDetails.taxItems = lineItem.taxItems || []
  vehicleDetails.fuelDiscountPerGallon = lineItem.fuelDiscountPerGallon || 0
  vehicleDetails.totalFuelDiscount = lineItem.totalFuelDiscount || 0
  vehicleDetails.isEquipment = get(lineItem, 'fleetVehicle.isEquipment', false)

  return {
    vehicleDetails: vehicleDetails as FueledAssetsTableRow,
    vehicleFeeItem,
  }
}

function getFuelValue(
  lineItem: LineItemExtended,
  productsDictionary: ProductsDictionary
) {
  if (productsDictionary[lineItem.productId]) {
    return getEnergyTypeLabel(productsDictionary[lineItem.productId].fuelType)
  }

  return '-'
}

function getVehicleFeeItem(
  lineItem: LineItemExtended,
  vehicleFeeItems: LineItemExtended[]
) {
  if (vehicleFeeItems.length === 0) {
    return undefined
  }

  const vehicleFeeItem = vehicleFeeItems.find(
    (li) => li.fleetVehicleId && li.fleetVehicleId === lineItem.fleetVehicleId
  )

  return vehicleFeeItem
}

function hasProvidedSubAccountId(
  lineItem: LineItemExtended,
  subAccountId: string
): boolean {
  if (subAccountId === 'ALL') {
    return true
  }
  const itemsSubAccountId = get(lineItem, 'fleetVehicle.subAccount._id') || ''
  return itemsSubAccountId === subAccountId
}

function sortVisitFeesToTop(a, b) {
  if (a.item === 'Visit fee' && b.item !== 'Visit fee') {
    return 1
  }
  if (a.item !== 'Visit fee' && b.item === 'Visit fee') {
    return -1
  }
  return 0
}
