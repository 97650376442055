import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { CdkTableDataSource } from '../../../_shared/cdk-table/cdk-table-data-source'
import { IncludedService, IncludedServices } from '../typings'

@Component({
  selector: 'billing-details-service-table',
  templateUrl: 'billing-details-service-table.component.html',
  styleUrls: ['billing-details-service-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingDetailsServiceTableComponent {
  @Input() set includedServices(value: IncludedServices) {
    if (value) {
      this.dataSource.data = Object.values(value).map(({ service }) => service)
    }
  }

  public displayedColumns = [
    'serviceDate',
    'totalCharged',
    'totalGallonsDispensed',
    'link',
  ]

  public dataSource: CdkTableDataSource<IncludedService> =
    new CdkTableDataSource<IncludedService>()
}
