import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FleetInvoice } from '@boosterfuels/types/types-ts'
import { AuthService } from '../../../_core/services/auth.service'
import { isBoolean } from 'lodash'
import { format, isValid, parse } from 'date-fns'

@Component({
  selector: 'billing-details-total',
  templateUrl: 'billing-details-total.component.html',
  styleUrls: ['billing-details-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingDetailsTotalComponent {
  @Input() autoPay = false
  @Input() fleetInvoice: FleetInvoice

  constructor(private _authService: AuthService) {}

  public getIsAutoPayValueSet(): boolean {
    return isBoolean(this.autoPay)
  }

  public formatDueDate(dueDate: string): string {
    const date = parse(dueDate, 'yyyyMMdd', new Date())
    return isValid(date) ? format(date, 'MMM d, yyyy') : '-'
  }

  public payInvoice(payNowLink: string): void {
    const {
      _id: fleetInvoiceId,
      fleetCompanyId,
      fleetAccountId,
      netsuiteRecordId,
    } = this.fleetInvoice
    const userId = this._authService.getUserId()
    window.analytics.track('payInvoice', {
      fleetInvoiceId,
      fleetCompanyId,
      fleetAccountId,
      netsuiteRecordId,
      userId,
    })
    window.open(payNowLink, '_blank')
  }
}
