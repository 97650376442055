<rmd-card>
  <rmd-card-content>
    <cdk-table buiTable buiTableResponsive [dataSource]="dataSource">
      <ng-container cdkColumnDef="serviceDate">
        <cdk-header-cell *cdkHeaderCellDef>Service Date</cdk-header-cell>
        <cdk-cell *cdkCellDef="let row">
          <span buiTableResponsiveLabel>Service Date</span>
          {{ row.serviceDate | dateStringToDate | date: 'MM/dd/yyyy' }}
        </cdk-cell>
        <cdk-footer-cell *cdkFooterCellDef></cdk-footer-cell>
      </ng-container>

      <ng-container cdkColumnDef="totalCharged">
        <cdk-header-cell *cdkHeaderCellDef>Order Total</cdk-header-cell>
        <cdk-cell *cdkCellDef="let row">
          <span buiTableResponsiveLabel>Order Total</span>
          {{ row.totalCharged | currency: 'USD' : 'symbol' : '1.3-3' }}
        </cdk-cell>
        <cdk-footer-cell *cdkFooterCellDef></cdk-footer-cell>
      </ng-container>

      <ng-container cdkColumnDef="totalGallonsDispensed">
        <cdk-header-cell *cdkHeaderCellDef>Gallons</cdk-header-cell>
        <cdk-cell *cdkCellDef="let row">
          <span buiTableResponsiveLabel>Gallons</span>
          {{ row.totalGallonsDispensed | number: '1.0-0' }}
        </cdk-cell>
        <cdk-footer-cell *cdkFooterCellDef></cdk-footer-cell>
      </ng-container>

      <ng-container cdkColumnDef="link">
        <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
        <cdk-cell *cdkCellDef="let row">
          <span buiTableResponsiveLabel></span>
          <a [routerLink]="['/services', row._id]" class="view-details">
            <span class="view-details__text">View details</span>
            <bui-icon name="arrow-right" />
          </a>
        </cdk-cell>
        <cdk-footer-cell *cdkFooterCellDef></cdk-footer-cell>
      </ng-container>

      <cdk-header-row *cdkHeaderRowDef="displayedColumns"></cdk-header-row>
      <cdk-row *cdkRowDef="let row; columns: displayedColumns"></cdk-row>
    </cdk-table>
  </rmd-card-content>
</rmd-card>
