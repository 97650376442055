import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { format, parse } from 'date-fns'
import { FleetInvoice } from '@boosterfuels/types/types-ts'
import { getInvoiceStatusColor } from '../../../_core/services/colors.service'
import { IncludedServices } from '../typings'

@Component({
  selector: 'billing-details-summary',
  templateUrl: 'billing-details-summary.component.html',
  styleUrls: ['billing-details-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingDetailsSummaryComponent {
  @Input() fleetInvoice: FleetInvoice
  @Input() includedServices: IncludedServices
  @Input() totalGallons: number

  get includedServicesCount() {
    const length = Object.keys(this.includedServices || {}).length

    if (!length) return 'N/A'
    return length
  }

  public getStatusColor = getInvoiceStatusColor

  public formatDate(date: string): string {
    return format(parse(date, 'yyyyMMdd', new Date()), 'MM/dd/yyyy')
  }

  public formatStatus({ status, amountPaid }: FleetInvoice): string {
    if (status === 'paidInFull') {
      return 'Fully paid'
    }

    return amountPaid === 0 ? 'Not paid' : 'Partially paid'
  }
}
