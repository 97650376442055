<rmd-card>
  <rmd-card-content>
    <div class="billing-details-total__title">Total:</div>
    <div class="billing-details-total__row">
      <div class="billing-details-total__amount">
        <div class="total-costs">
          {{ fleetInvoice.totalCosts | currency: 'USD' : 'symbol' : '1.2-2' }}
        </div>
        <div *ngIf="fleetInvoice.status !== 'paidInFull'" class="due-date">
          Pay by: {{ formatDueDate(fleetInvoice.dueDate) }}
        </div>
      </div>
      <div
        class="billing-details-total__pay-button"
        *ngIf="
          fleetInvoice.status !== 'paidInFull' &&
          fleetInvoice.payNowLink &&
          autoPay === false
        "
      >
        <bui-button
          variant="utility"
          (click)="payInvoice(fleetInvoice.payNowLink)"
        >
          Pay invoice
        </bui-button>
      </div>
    </div>
    <div class="billing-details-total__row">
      <div *ngIf="getIsAutoPayValueSet()" class="info">
        Autopay: {{ autoPay ? 'On' : 'Off' }}
      </div>
      <!-- <div class="action">Set up autopay</div> -->
    </div>
  </rmd-card-content>
</rmd-card>
