import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'billing-details-title',
  templateUrl: 'billing-details-title.component.html',
  styleUrls: ['billing-details-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingDetailsTitleComponent {
  @Input() invoiceNumber: string

  constructor(private router: Router) {}

  public goBack(): void {
    this.router.navigate(['/billing'])
  }
}
