import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core'
import { combineLatest, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AppStateWithBillingDetails } from './store/billing-details.reducer'
import { billingDetailsActions } from './store/billing-details.actions'
import { FleetInvoice } from '@boosterfuels/types/types-ts'
import {
  selectBillingDetails,
  selectNetsuiteCustomerId,
} from './store/billing-details.selector'
import { AuthService } from '../../_core/services/auth.service'
import { LayoutService } from '../../_core/layout/layout.service'
import { BillingDetailsDefInvoiceModalComponent } from './billing-details-def-invoice-modal/billing-details-def-invoice-modal.component'
import { BuiModalService } from '@fleet-customer/booster-ui'

@Component({
  templateUrl: 'billing-details.component.html',
  styleUrls: ['billing-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingDetailsComponent implements OnDestroy {
  private destroy$ = new Subject<void>()

  public readonly billingDetailsCtx$ = this.store.select(selectBillingDetails)

  private netsuiteCustomerId: string

  constructor(
    private store: Store<AppStateWithBillingDetails>,
    private authService: AuthService,
    private layoutService: LayoutService,
    private buiModalService: BuiModalService
  ) {
    this.layoutService.setUseLegacyLayout()

    combineLatest([
      this.store.select(selectNetsuiteCustomerId),
      this.store.select((state) => state.billingDetails),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([netsuiteCustomerId]) => {
        if (!this.netsuiteCustomerId && netsuiteCustomerId) {
          this.netsuiteCustomerId = netsuiteCustomerId
          this.store.dispatch(
            billingDetailsActions.GET_AUTO_PAY_VALUE(this.netsuiteCustomerId)
          )
        }
      })
  }

  // data is fetched in BillingDetailsResolver

  ngOnDestroy(): void {
    this.store.dispatch(billingDetailsActions.LEAVE_PAGE())
    this.destroy$.next()
    this.destroy$.complete()
  }

  public onDownloadInvoicePdf(
    fleetInvoice: FleetInvoice,
    isMiscOrDefInvoice: boolean
  ): void {
    this.track('viewInvoice', fleetInvoice)
    if (isMiscOrDefInvoice) {
      this.buiModalService.open(BillingDetailsDefInvoiceModalComponent, {
        data: { invoiceNumber: fleetInvoice.invoiceNumber },
      })
    } else {
      this.store.dispatch(
        billingDetailsActions.DOWNLOAD_FLEET_INVOICE(fleetInvoice._id)
      )
    }
  }

  public onDownloadVehicleDetailsCsv(fleetInvoice: FleetInvoice): void {
    this.track('downloadInvoice', fleetInvoice)
    this.store.dispatch(billingDetailsActions.DOWNLOAD_VEHICLE_DETAILS_CSV())
  }

  private track(
    action: 'downloadInvoice' | 'viewInvoice',
    fleetInvoice: FleetInvoice
  ): void {
    const trackingData: { [key: string]: string } = {
      fleetInvoiceId: fleetInvoice._id,
      fleetCompanyId: fleetInvoice.fleetCompanyId,
      fleetAccountId: fleetInvoice.fleetAccountId,
      netsuiteRecordId: fleetInvoice.netsuiteRecordId,
      userId: this.authService.getUserId(),
    }
    if (action === 'downloadInvoice') {
      trackingData['format'] = 'CSV'
    }
    window.analytics.track(action, trackingData)
  }
}
