<rmd-card class="billing-details-summary">
  <div class="billing-details-summary__item">
    <h4>Date:</h4>
    <p>{{ formatDate(fleetInvoice.date) }}</p>
  </div>

  <div
    *ngIf="fleetInvoice.includedServices?.length"
    class="billing-details-summary__item"
  >
    <h4>Services:</h4>
    <p>{{ includedServicesCount }}</p>
  </div>

  <div *ngIf="totalGallons" class="billing-details-summary__item">
    <h4>Gallons:</h4>
    <p>{{ totalGallons | number: '1.3-3' }}</p>
  </div>

  <div class="billing-details-summary__item">
    <h4>Status:</h4>
    <p [style.color]="getStatusColor(fleetInvoice)">
      {{ formatStatus(fleetInvoice) }}
      <span
        *ngIf="fleetInvoice.status === 'open' && fleetInvoice.amountPaid > 0"
        class="partially-paid-details"
      >
        (<span class="partially-paid-details__amount-paid">{{
          fleetInvoice.amountPaid | currency
        }}</span>
        / {{ fleetInvoice.totalCosts | currency }})
      </span>
    </p>
  </div>
</rmd-card>
