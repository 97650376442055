<section *ngIf="billingDetailsCtx$ | async as ctx; else loading">
  <div class="grid-container">
    <div class="section-header">
      <div class="section-header-bar">
        <billing-details-title
          [invoiceNumber]="ctx.fleetInvoice.invoiceNumber"
        ></billing-details-title>
        <div class="section-header__actions">
          <bui-button
            variant="secondary"
            [disabled]="ctx.isFleetInvoiceDownloading"
            [loading]="ctx.isFleetInvoiceDownloading"
            (click)="
              onDownloadInvoicePdf(ctx.fleetInvoice, ctx.isMiscOrDefInvoice)
            "
          >
            View Invoice
          </bui-button>
          <rmd-export-button
            *ngIf="ctx.fueledAssets?.length"
            [options]="[{ value: 'CSV', label: 'CSV' }]"
            (export)="onDownloadVehicleDetailsCsv(ctx.fleetInvoice)"
          ></rmd-export-button>
        </div>
      </div>
    </div>

    <div class="grid-row total-and-credit-memos-row">
      <div
        class="col"
        [class.col-md-3]="ctx.appliedCreditMemosAmount"
        [class.col-md-6]="!ctx.appliedCreditMemosAmount"
      >
        <billing-details-total
          [fleetInvoice]="ctx.fleetInvoice"
          [autoPay]="ctx.autoPay"
        ></billing-details-total>
      </div>

      <div *ngIf="ctx.appliedCreditMemosAmount" class="col col-md-3">
        <rmd-card class="summary-card applied-credit-memos-amount-card">
          <rmd-card-content>
            <div class="summary-card__title">Applied credit memos</div>
            <div class="summary-card__row">
              <div class="summary-card__amount">
                <div class="total-costs">
                  {{
                    ctx.appliedCreditMemosAmount
                      | currency: 'USD' : 'symbol' : '1.2-2'
                  }}
                </div>
              </div>
            </div>
          </rmd-card-content>
        </rmd-card>
      </div>

      <div class="col col-md-6">
        <billing-details-summary
          [fleetInvoice]="ctx.fleetInvoice"
          [includedServices]="ctx.includedServices"
          [totalGallons]="ctx.totalGallons"
        ></billing-details-summary>
      </div>
    </div>

    <div>
      <billing-details-service-table
        [includedServices]="ctx.includedServices"
      ></billing-details-service-table>
    </div>
  </div>
</section>

<ng-template #loading>
  <section class="with-loader">
    <rmd-loader class="section-loader"></rmd-loader>
  </section>
</ng-template>
