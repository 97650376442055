<div class="billing-page-details-title">
  <div class="back-button" (click)="goBack()">
    <bui-icon name="arrow-left"></bui-icon>
  </div>
  <div class="title">
    <div class="prefix">Billing</div>
    <div class="slash">/</div>
    <div class="invoice-number">Invoice #{{ invoiceNumber }}</div>
  </div>
</div>
