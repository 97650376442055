import { createAction } from '@ngrx/store'
import { FleetInvoiceResponse } from '../../_core/models/fleet-invoice.models'
import { FindOptions } from './billing-index.reducer'
import { GetUnappliedCreditMemosAmountResponse } from '../../_core/models/credit-memo.models'
import { SelectFleetCreator } from '../../_core/root-store/user/user.actions'

export const billingIndexActions = {
  FIND_FLEET_INVOICES: createAction('[Billing Index Page] Find Fleet Invoices'),

  FIND_FLEET_INVOICES_SUCCESS: createAction(
    '[Fleet Invoice Api] Find Fleet Invoices Success',
    (response: FleetInvoiceResponse) => ({ payload: response })
  ),

  FIND_FLEET_INVOICES_ERROR: createAction(
    '[Fleet Invoice Api] Find Fleet Invoices Error'
  ),

  SET_FLEET_INVOICES_FIND_OPTIONS: createAction(
    '[Billing Index Page] Set Fleet Invoices Find Options',
    (findOptions: Partial<FindOptions>) => ({ payload: findOptions })
  ),

  GET_UNAPPLIED_CREDIT_MEMOS_AMOUNT: createAction(
    '[Billing Index Page] Get Unapplied Credit Memos Amount'
  ),

  GET_UNAPPLIED_CREDIT_MEMOS_AMOUNT_SUCCESS: createAction(
    '[Credit Memos Api] Get Unapplied Credit Memos Amount Success',
    (response: GetUnappliedCreditMemosAmountResponse) => ({ payload: response })
  ),

  GET_UNAPPLIED_CREDIT_MEMOS_AMOUNT_ERROR: createAction(
    '[Credit Memos Api] Get Unapplied Credit Memos Amount Error'
  ),

  SELECT_FLEET_FROM_BILLING_PAGE_GUARD: createAction(
    '[Billing Page Guard] Select Fleet',
    SelectFleetCreator
  ),
}
