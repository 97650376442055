<bui-modal-layout size="large">
  <bui-modal-header>
    <bui-title element="h1">
      This invoice type cannot be downloaded<br />
      via Booster Customer Portal.
    </bui-title>
  </bui-modal-header>
  <bui-modal-content>
    <p>
      Please contact your account manager at
      <a href="mailto:fleetservices@boosterfuels.com">
        fleetservices&#64;boosterfuels.com
      </a>
      <br />
      and provide the invoice reference number:
    </p>
    <div class="invoice-number-container">
      <div class="invoice-number">
        <span class="text">
          {{ invoiceNumberText }}
        </span>
        <bui-button
          class="btn"
          (click)="copyToClipboard()"
          icon="copy-to-clipboard"
          [size]="'small'"
          buiTooltip="Copy to clipboard"
        >
        </bui-button>
      </div>
    </div>
  </bui-modal-content>
  <bui-modal-actions>
    <bui-button (click)="close()">Close</bui-button>
  </bui-modal-actions>
</bui-modal-layout>
