import { createAction } from '@ngrx/store'
import {
  DownloadFleetInvoiceResponse,
  FleetInvoiceResponse,
} from '../../../_core/models/fleet-invoice.models'
import { GetAppliedCreditMemosAmountResponse } from '../../../_core/models/credit-memo.models'
import { SelectFleetCreator } from '../../../_core/root-store/user/user.actions'
import { FueledAssetsFindOptions } from '../typings'

export const billingDetailsActions = {
  FIND_FLEET_INVOICE: createAction(
    '[Billing Details Page] Find Fleet Invoice',
    (fleetInvoiceId: string) => ({ payload: fleetInvoiceId })
  ),

  FIND_FLEET_INVOICE_SUCCESS: createAction(
    '[Fleet Invoice Api] Find Fleet Invoice Success',
    (response: FleetInvoiceResponse) => ({ payload: response })
  ),

  FIND_FLEET_INVOICE_ERROR: createAction(
    '[Fleet Invoice Api] Find Fleet Invoice Error'
  ),

  GET_AUTO_PAY_VALUE: createAction(
    '[Billing Details Page] Get Auto Pay Value',
    (netsuiteCustomerId: string) => ({ payload: netsuiteCustomerId })
  ),

  GET_AUTO_PAY_VALUE_SUCCESS: createAction(
    '[Fleet Invoice Api] Get Auto Pay Value Success',
    (statusText: string) => ({ payload: statusText })
  ),

  GET_AUTO_PAY_VALUE_ERROR: createAction(
    '[Fleet Invoice Api] Get Auto Pay Value Error'
  ),

  GET_APPLIED_CREDIT_MEMOS_AMOUNT: createAction(
    '[Billing Index Page] Get Applied Credit Memos Amount',
    (invoiceNumber: string) => ({ payload: invoiceNumber })
  ),

  GET_APPLIED_CREDIT_MEMOS_AMOUNT_SUCCESS: createAction(
    '[Credit Memos Api] Get Applied Credit Memos Amount Success',
    (response: GetAppliedCreditMemosAmountResponse) => ({ payload: response })
  ),

  GET_APPLIED_CREDIT_MEMOS_AMOUNT_ERROR: createAction(
    '[Credit Memos Api] Get Applied Credit Memos Amount Error'
  ),

  DOWNLOAD_FLEET_INVOICE: createAction(
    '[Billing Details Page] Download Fleet Invoice',
    (fleetInvoiceId: string) => ({ payload: fleetInvoiceId })
  ),

  DOWNLOAD_FLEET_INVOICE_SUCCESS: createAction(
    '[Fleet Invoice Api] Download Fleet Invoice Success',
    (response: DownloadFleetInvoiceResponse) => ({
      payload: {
        response,
      },
    })
  ),

  DOWNLOAD_FLEET_INVOICE_ERROR: createAction(
    '[Fleet Invoice Api] Download Fleet Invoice Error'
  ),

  VIEW_INVOICE: createAction('[Billing Details Page] View Invoice'),

  DOWNLOAD_VEHICLE_DETAILS_CSV: createAction(
    '[Billing Details Page] Download Vehicle Details CSV'
  ),

  SELECT_FLEET: createAction(
    '[Billing Details Page] Select Fleet',
    SelectFleetCreator
  ),

  SET_ORIGIN_FLEET: createAction('[Billing Details Page] Set Origin Fleet IDs'),

  LEAVE_PAGE: createAction('[Billing Details Page] Leave Page'),
}
