import { Component, Inject } from '@angular/core'
import { BUI_MODAL_DATA, BuiModalRef } from '@fleet-customer/booster-ui'

@Component({
  selector: 'billing-details-def-invoice-modal',
  templateUrl: 'billing-details-def-invoice-modal.component.html',
  styleUrls: ['billing-details-def-invoice-modal.component.scss'],
})
export class BillingDetailsDefInvoiceModalComponent {
  public invoiceNumberText

  constructor(
    private buiModalRef: BuiModalRef<BillingDetailsDefInvoiceModalComponent>,
    @Inject(BUI_MODAL_DATA)
    public data: {
      invoiceNumber: string
    }
  ) {
    this.invoiceNumberText = data.invoiceNumber
  }

  public copyToClipboard(): void {
    navigator.clipboard.writeText(this.data.invoiceNumber)
    this.invoiceNumberText = 'Copied!'

    setTimeout(() => {
      this.invoiceNumberText = this.data.invoiceNumber
    }, 2000)
  }

  public close(): void {
    this.buiModalRef.close()
  }
}
